import * as React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import Footer from "./UI/footer"
import NavBar from "./UI/nav"
import { useStaticQuery, graphql } from "gatsby"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { name: { eq: "home" } }) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                details {
                  email
                  tel
                  donate
                  twitter
                }
              }
            }
          }
        }
      }
    }
  `)

  const { details } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <>
      <NavBar details={details} />
      <main>{children}</main>
      <Footer details={details} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
