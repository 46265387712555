import React from "react"
import styled from "styled-components"
import { Link } from "gatsby-plugin-modal-routing-3"
import { TwitterIcon } from "./Buttons"

const Foot = styled.div`
  background: var(--cafe-color-blue);
  position: relative;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;

  justify-content: space-around;
  align-items: center;

  .subscript {
    font-size: 0.6em;
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    flex-wrap: wrap;
    justify-items: center;
    padding: 0 1em;

    .nav-item {
      margin: 1em 0;
    }
  }
`
const Icons = styled.div`
  display: flex;
  justify-content: space-around;

  :hover {
    fill: var(--cafe-color-orange);
    transform: scale(1.05);
    transition: 0.2s;
  }
`

const Footer = ({ details }) => {
  const { donate, email, twitter } = details
  return (
    <Foot>
      <p className="nav-item">© 2021 Cafe Togther</p>
      <Link className="nav-item" to="/cookie-policy" asModal>
        Cookie Policy
      </Link>
      <Link className="nav-item" to="/privacy-policy" asModal>
        Privacy Policy
      </Link>
      <p className="nav-item">Reg. Charity #1158760</p>
      <a href="#find-us" className="nav-item">
        Find Us
      </a>
      <a href="#get-involved" className="nav-item">
        Volunteer
      </a>
      <a href="#booking" className="nav-item">
        Booking
      </a>
      <a
        href={donate}
        target="blank"
        rel="noopener noreferrer"
        className="nav-item"
      >
        Donate
      </a>
      <a
        href={`mailto:${email}`}
        target="blank"
        rel="noopener noreferrer"
        className="nav-item"
      >
        Contact Us
      </a>
      <Icons>
        <TwitterIcon link={twitter} />
      </Icons>
    </Foot>
  )
}

export default Footer
