import React from "react"
import styled from "styled-components"
import { ModalRoutingContext, Link } from "gatsby-plugin-modal-routing-3"
import InstaLogo from "../../../static/assets/icons/Instagram.svg"
import TwitterLogo from "../../../static/assets/icons/Twitter.svg"
import LinkedLogo from "../../../static/assets/icons/LinkedIn.svg"

const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: fit-content;
    min-width: 120px;
    min-height: 35px;
    background: var(--cafe-color-red);
    box-shadow: 2.4128px 2.4128px 4.8256px rgba(0, 0, 0, 0.25);
    border-radius: 2.08px;
    border: none;
    cursor: pointer;
    color: white;
    padding: 0;
    text-decoration: none;
    z-index: 5;


    &.small-text {
        padding: 2px 16px;
    }

    a:visited {
      text-decoration: none;
      color: unset;
    }


    :hover {
        background: white !important;
        color: black;
        transform: scale(1.1);
        transition: all 0.3s;
    }

    :active,focus {
        transform: scale(0);
        border-color: none;

        * {color: white;}
    }

    &.linkedIn {
        width: 90px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        
        
        :active,focus {
            transform: scale(1.1);
            border-color: #000;
            filter: invert(100%);
            -webkit-filter: invert(100%);
        }
    }

    &.white {
        background: rgba(255, 255, 255, 0.25);
        border: 0.2px solid #fff;
        border-radius: 12px;

        * {color: white;}
        

        :hover {
            background: rgba(255, 255, 255, 0.5);
        }

        :active,focus {
            transform: scale(1.1);
            border-color: #000;
            
            * {color: #000;}
        }
    }

    &.black {
        background: rgba(0, 0, 0, 1);
        border: 0.2px solid #000;   
        padding: 1px 12px;

            &.submit {
                padding: 2px 22px;

            :disabled {
                    background: #AAA;
                    border: 0.2px solid #AAA;
                }
            }
    

            * {color: white;}

            :hover {
                background: rgba(50, 50, 50, 0.75);
                }

            :active,focus {
                transform: scale(1.1);
                background-color: white;
                border: 0.2px solid #fff;  

                * {color: #000;}}
            }

        @media (max-width: 768px) {
            margin: auto;
            margin-top: 1em;
        }
    }

    &.footer {
        margin: 30px 30px 30px auto;

      @media (max-width: 768px) {
        margin: 30px auto;
      }
    }

    * {
        text-decoration: none;
        -webkit-user-select: none;  
        -moz-user-select: none;     
        -ms-user-select: none;     
        user-select: none;
    }
`

const HoverRef = styled.a`
  text-align: center;

  * {
    height: 50px;
    width: 50%;
  }
`

const OpenButton = styled(Link)`
  align-self: flex-end;
  transition: transform 300ms;

  :hover {
    transform: scale(1.3);
  }
`

export const PageButton = props => {
  return (
    <Button className={`${props.styleName}`}>
      <Link to={props.linkRef} style={{ whiteSpace: "nowrap" }}>
        {props.children}
      </Link>
    </Button>
  )
}

export const ModalButton = props => {
  return (
    <ModalRoutingContext.Consumer>
      {({ closeTo }) => (
        <Button className={`${props.styleName}`}>
          <Link to={closeTo} style={{ whiteSpace: "nowrap" }}>
            {props.children}
          </Link>
        </Button>
      )}
    </ModalRoutingContext.Consumer>
  )
}

export const PopButton = props => {
  return (
    <Button className="black">
      <Link
        to={props.linkRef}
        style={{ whiteSpace: "nowrap" }}
        state={{ modal: true, noScroll: true }}
      >
        {props.children}
      </Link>
    </Button>
  )
}

export const SubmitButton = props => {
  return (
    <Button
      as="button"
      type="submit"
      onClick={props.click}
      disabled={props.disabled}
      className={`${props.styleName} button-text`}
      style={{ background: `${props.buttonColor}` }}
    >
      <div>{props.children}</div>
    </Button>
  )
}

//These following are the icons for social following section and menu.

export const HrefButton = props => {
  return (
    <Button
      as="a"
      href={props.linkRef}
      className={`${props.styleName} submit`}
      style={{ background: `${props.buttonColor}` }}
      target="blank"
      rel="noopener noreferrer"
    >
      {props.children}
    </Button>
  )
}

export const LinkedInIcon = () => {
  return (
    <HoverRef
      href="https://www.linkedin.com/company/cube-capital-ltd"
      target="_blank"
      rel="noreferrer"
    >
      <LinkedLogo />
    </HoverRef>
  )
}

export const TwitterIcon = ({ link }) => {
  return (
    <HoverRef href={link} target="_blank" rel="noreferrer">
      <TwitterLogo />
    </HoverRef>
  )
}

export const InstagramIcon = () => {
  return (
    <HoverRef
      href="https://www.instagram.com/"
      target="_blank"
      rel="noreferrer"
    >
      <InstaLogo />
    </HoverRef>
  )
}

export const PlusButton = ({ slug }) => {
  return (
    <OpenButton asModal to={slug}>
      <div className="plus-button" />
    </OpenButton>
  )
}
