import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import { useTransition, animated } from "react-spring"
import PropTypes from "prop-types"
import styled from "styled-components"
import Logo from "../../../static/assets/icons/navlogo.svg"
import MobileLogo from "../../../static/assets/icons/mobile-logo.svg"
import useMediaQuery from "../Hooks/MatchMedia"
import { HrefButton } from "./Buttons"

const StickyBox = styled.div`
  position: -webkit-sticky;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100vw;
`

const NavBox = styled.div`
  position: relative;
  height: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-content: center;

  .logo-box {
    margin-left: var(--body-margin-sides);
    background: white;
    width: fit-content;
    transform: translate(0, 20px);
    height: 140px;
    z-index: 22;
    padding: 80px 8px 12px 8px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 2px 2px;
  }

  @media (min-width: 1024px) {
    .logo-box {
      height: unset;
    }
  }
`

const Toggle = styled.div`
  display: flex;
  height: 100%;
  cursor: pointer;
  margin-right: var(--body-margin-sides);
  justify-content: end;
`

const Menu = styled(animated.div)`
  position: relative;
  width: 100%;
  top: -20px;
  background: var(--cafe-color-orange);
  z-index: 1;

  a {
    color: #fff;
    display: block;
    width: fit-content;
    font-size: 1.25em;
    line-height: 100%;
    padding: 0.5em 0;
    margin-left: auto;
    margin-right: 32px;

    @media (max-width: 768px) {
      font-weight: 600;
    }
  }
`

const Hamburger = styled.div`
  background-color: #fff;
  width: 30px;
  height: 2px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 2px;
    background-color: #fff;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
      props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`

const NavBar = ({ details }) => {
  const { tel, email, donate } = details
  const [navbarOpen, setNavbarOpen] = useState(false)
  const navbar = useRef()

  //if 1024px or BIGGER
  let mobile = useMediaQuery("(max-width: 1024px)")

  const appear = useTransition(navbarOpen, {
    from: { right: "-100%" },
    enter: { right: "0%" },
    leave: { right: "-100%" },
  })

  const NavLinks = () => {
    return (
      <div className="flex">
        <a href="#find-us" className="nav-item">
          Find Us
        </a>
        <a
          href={donate}
          className="nav-item"
          target="blank"
          rel="noopener noreferrer"
        >
          Donate
        </a>
        <a href="#get-involved" className="nav-item">
          Volunteer
        </a>
        <a href="#booking" className="nav-item">
          Booking
        </a>
        {!mobile ? (
          <a
            href={`mailto:${email}`}
            target="blank"
            rel="noopener noreferrer"
            className="nav-item"
          >
            Contact
          </a>
        ) : (
          <>
            <a
              href={`tel:${tel}`}
              target="blank"
              rel="noopener noreferrer"
              className="nav-item"
            >
              Contact
            </a>

            <p
              className="button-text"
              style={{
                textAlign: "end",
                marginRight: "32px",
                paddingBottom: "1em",
              }}
            >
              <br />
              {email}
              <br />
              call: {tel}
            </p>
          </>
        )}
      </div>
    )
  }

  if (mobile) {
    return (
      <StickyBox>
        <NavBox style={{ background: "var(--cafe-color-blue" }}>
          <div className="logo-box">
            <Link to="/#">
              <MobileLogo />
            </Link>
          </div>
          <div />
          <Toggle onClick={() => setNavbarOpen(!navbarOpen)} ref={navbar}>
            {navbarOpen ? <Hamburger open /> : <Hamburger />}
          </Toggle>
        </NavBox>
        <NavBox style={{ background: "var(--cafe-color-orange" }}></NavBox>
        {appear(
          (appear, item) =>
            item && (
              <Menu onClick={() => setNavbarOpen(!navbarOpen)} style={appear}>
                <NavLinks />
              </Menu>
            )
        )}
      </StickyBox>
    )
  } else {
    return (
      <StickyBox>
        <NavBox style={{ background: "var(--cafe-color-blue" }}>
          <div className="logo-box">
            <Link to="/#">
              <Logo />
            </Link>
          </div>
          <div />

          <NavLinks />
        </NavBox>
        <NavBox style={{ background: "var(--cafe-color-orange" }}>
          <div />
          <div className="flex">
            <p className="button-text">
              {email} - {tel}
            </p>
          </div>
          <div className="flex">
            <p className="button-text">Want to volunteer?</p>
            <HrefButton linkRef={"#get-involved"}>Get Involved</HrefButton>
          </div>
          <div />
        </NavBox>
      </StickyBox>
    )
  }
}

NavBar.propTypes = {
  navbarOpen: PropTypes.bool,
}

export default NavBar
